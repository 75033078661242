import React from "react";

const getInitialTheme = () => {
    if (typeof window !== "undefined" && window.localStorage) {
        const storedPrefs = window.localStorage.getItem("color-theme");

        if (typeof storedPrefs === "string") return storedPrefs;

        const hours = new Date().getHours();
        const isDayTime = hours > 6 && hours < 20;

        if (isDayTime) return "light";
        return "dark";
    }

    return "light";
};

const useDarkMode = () => {
    const [isDarkMode, setIsDarkMode] = React.useState(getInitialTheme() === "dark");

    const toggleDarkMode = React.useCallback(() => {
        if (typeof window !== "undefined") {
            if (isDarkMode) {
                localStorage.setItem("color-theme", "dark");
            }
            localStorage.setItem("color-theme", "light");
        }
        setIsDarkMode((prevIsDarkMode) => !prevIsDarkMode);
    }, [isDarkMode]);

    return { isDarkMode, toggleDarkMode };
};

export default useDarkMode;
