import React from "react";
import { Layout } from "@nubeteck/components";
import { Loading } from "./components";
import { useTranslation } from "react-i18next";

const MainNavigation = React.lazy(() => import("./navigation/navigation"));

const Application = () => {
    const { t, i18n } = useTranslation();

    console.log(t("key", { ns: "example_module" }));
    console.log(i18n.language);

    const renderLoading = React.useCallback(() => {
        return (
            <Layout style={{ width: "100vw", height: "100vh" }}>
                <Loading />
            </Layout>
        );
    }, []);

    return (
        <React.Suspense fallback={renderLoading()}>
            <MainNavigation />
        </React.Suspense>
    );
};

export default Application;
