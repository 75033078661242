import styled from "styled-components";
import { themeColors } from "@core";

// Estilos para el componente cuando el mouse está encima
export const HoverableContainer = styled.div<{ isHovered: boolean }>`
    width: 90%;
    height: 17%;
    border-radius: 20px;
    border: 1px solid #ccc;
    background-color: ${(props) => (props.isHovered ? "#ccc" : "white")};
    margin-left: 30px;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
        background-color: ${themeColors.primary500};
        border: 1px solid ${themeColors.primary500};
        color: white;
    }
`;

export const TextContainer = styled.div`
    margin-left: 25px;
`;

export const Title = styled.h3<{ isHovered: boolean }>`
    color: ${(props) => (props.isHovered ? "white" : "#444444")};
    padding-top: 5px;
`;

export const SubTitle = styled.h5<{ isHovered: boolean }>`
    color: ${(props) => (props.isHovered ? "white" : "#444444")};
    margin-top: -7px;
`;

export const Number = styled.h2<{ isHovered: boolean }>`
    color: ${(props) => (props.isHovered ? "white" : "#444444")};
    padding: 30px;
`;
