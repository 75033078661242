import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
// import jwtDecode from "jwt-decode";

// export interface IToken {
//     aud: string | number | null;
//     exp: string | number | null;
//     iat: string | number | null;
//     id: string | number | null;
//     iss: string | number | null;
//     nbf: string | number | null;
//     roles: string | number | null;
//     username: string | number | null;
// }
// const token: string | null = localStorage.getItem("token");
// const decode: IToken | null = token ? jwtDecode(token) : null;

export const perfilApi = createApi({
    reducerPath: "perfilApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem("token");
            // Asegúrate de que el token no sea nulo antes de agregarlo a los encabezados
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        User: builder.mutation({
            query: () => {
                return {
                    url: "Users/Perfil",
                    method: "POST",
                };
            },
        }),
    }),
});

export const { useUserMutation } = perfilApi;
