import styled from "styled-components";
import { Heading, Text, Image, Flex, Card } from "@nubeteck/components";

import { devices } from "../../constants";

export const HeadingStyled = styled(Heading)`
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 30px !important;
    color: ${({ theme }) => theme.colors.notificationsHeading} !important;
    margin-bottom: 20px !important;
    text-align: left;
`;

export const SubHeadingStyled = styled(Heading)`
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: ${({ theme }) => theme.colors.notificationsText} !important;

    @media ${devices.lg} {
        text-align: left;
    }
`;

export const TextStyled = styled(Text)`
    font-size: 14px !important;
    line-height: 18px !important;
    color: ${({ theme }) => theme.colors.notificationsText} !important;
`;

export const TextDescStyled = styled(Text)`
    font-size: 14px !important;
    line-height: 18px !important;
    color: ${({ theme }) => theme.colors.notificationsText} !important;
    max-width: 253px;
`;

export const ImageStyled = styled(Image)`
    border-radius: 10px;
    min-width: 113.5px;
    min-height: 70px;
`;

export const NotificationsDivContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    @media ${devices.lg} {
        column-gap: 32px;
        margin-top: 40px;
    }

    @media ${devices.xl} {
    }
`;

export const NotificationsDivHeader = styled(Flex)`
    flex-direction: column;
    text-align: center;
`;

export const NotificationsDivSettings = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 32px;
    text-align: center;
    margin-top: 16px;

    @media ${devices.lg} {
        flex-direction: row;
        align-items: center;
        column-gap: 96px;
        text-align: left;
    }
`;

export const NotificationsDivItems = styled(Flex)`
    flex-direction: column;
    row-gap: 16px;
`;

export const BaseCardStyled = styled(Card)`
    border: 1px solid;
    color: ${({ theme }) => theme.colors.inputBorder};
`;

export const NotificationsDivItem = styled(Flex)`
    display: flex !important;
    justify-content: space-between !important;
    column-gap: 32px;
    align-items: center;
    min-width: 310px;
`;

export const NotificationsDivDesc = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    max-width: 650px;
    min-width: 336px;
    column-gap: 16px;
    background-color: ${({ theme }) => theme.colors.colorTextHeading};
    border: 1px solid ${({ theme }) => theme.colors.inputBorder};
    border-radius: 10px;
    margin-bottom: 64px;
    padding: 16px;

    @media ${devices.lg} {
        margin-bottom: -16px;
    }
    @media ${devices.xl} {
        margin-bottom: 0;
        height: 330px;
    }
`;
