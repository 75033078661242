import styled from "styled-components";
import { Heading } from "@nubeteck/components";

import { devices } from "../../constants";

export const HeadingStyled = styled(Heading)`
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 30px !important;
    text-align: center !important;
    color: ${({ theme }) => theme.colors.integrationsHeading} !important;
`;

export const SubHeadingStyled = styled(Heading)`
    font-size: 14px !important;
    line-height: 18px !important;
    text-align: center !important;
    color: ${({ theme }) => theme.colors.integrationsSubHeading} !important;
`;

export const IntegrationsDivContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 100%;

    @media ${devices.lg} {
        align-items: flex-start;
        margin-top: 40px;
    }
`;

export const AppsDivContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin: 16px 0 32px 0;
    min-width: 250px;

    @media ${devices.lg} {
        width: 100%;
    }
`;
