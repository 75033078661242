import React from "react";

import { useForm, Controller } from "react-hook-form";
import {
    HeadingStyled,
    InputStyled,
    InputPasswordStyled,
    TextStyled,
    EmailConfigContainer,
    EmailConfigForm,
    EmailConfigRow1,
    EmailConfigColumn,
    EmailConfigRow2,
} from "./email-config.styled";

import { Tooltip, Button } from "@nubeteck/components";

const EmailConfig = () => {
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = (data: object) => {
        console.log(data);
        reset();
    };

    return (
        <EmailConfigContainer>
            <HeadingStyled>Configuración de correos electrónicos</HeadingStyled>
            <EmailConfigForm onSubmit={handleSubmit(onSubmit)}>
                <EmailConfigRow1>
                    <EmailConfigColumn>
                        <div>
                            <TextStyled>SMTP server</TextStyled>
                            <Controller
                                name="Server"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Este campo es obligatorio",
                                    },
                                    maxLength: 255,
                                }}
                                render={({ field }) => (
                                    <InputStyled placeholder="Express.com" {...field} />
                                )}
                            />
                            {errors.Server && (
                                <Tooltip
                                    open={errors.Server ? true : false}
                                    placement="topRight"
                                    title={`${errors.Server?.message}`}
                                    getPopupContainer={(node) => node.parentNode as HTMLElement}
                                />
                            )}
                        </div>
                        <div>
                            <TextStyled>Puerto</TextStyled>
                            <Controller
                                name="Port"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Este campo es obligatorio",
                                    },
                                    pattern: {
                                        value: /^\d+$/,
                                        message: "Este campo solo debe contener números",
                                    },
                                }}
                                render={({ field }) => <InputStyled placeholder="20" {...field} />}
                            />
                            {errors.Port && (
                                <Tooltip
                                    open={errors.Port ? true : false}
                                    placement="topRight"
                                    title={`${errors.Port?.message}`}
                                    getPopupContainer={(node) => node.parentNode as HTMLElement}
                                />
                            )}
                        </div>
                        <div>
                            <TextStyled>Nombre de usuario</TextStyled>
                            <Controller
                                name="Usuario"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Este campo es obligatorio",
                                    },
                                    maxLength: 255,
                                }}
                                render={({ field }) => (
                                    <InputStyled placeholder="debra.holt" {...field} />
                                )}
                            />
                            {errors.Usuario && (
                                <Tooltip
                                    open={errors.Usuario ? true : false}
                                    placement="topRight"
                                    title={`${errors.Usuario?.message}`}
                                    getPopupContainer={(node) => node.parentNode as HTMLElement}
                                />
                            )}
                        </div>
                    </EmailConfigColumn>
                    <EmailConfigColumn>
                        <div>
                            <TextStyled>Correo Electrónico</TextStyled>
                            <Controller
                                name="Email"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Este campo es obligatorio",
                                    },
                                    maxLength: 255,
                                    pattern: {
                                        value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                        message: "Dirección de correo electrónico no válida",
                                    },
                                }}
                                render={({ field }) => (
                                    <InputStyled placeholder="debra.holt@example.com" {...field} />
                                )}
                            />
                            {errors.Email && (
                                <Tooltip
                                    open={errors.Email ? true : false}
                                    placement="topRight"
                                    title={`${errors.Email?.message}`}
                                    getPopupContainer={(node) => node.parentNode as HTMLElement}
                                />
                            )}
                        </div>
                        <div>
                            <TextStyled>Nombre del remitente</TextStyled>
                            <Controller
                                name="Remitente"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Este campo es obligatorio",
                                    },
                                    maxLength: 255,
                                }}
                                render={({ field }) => (
                                    <InputStyled placeholder="Dashboard server" {...field} />
                                )}
                            />
                            {errors.Remitente && (
                                <Tooltip
                                    open={errors.Remitente ? true : false}
                                    placement="topRight"
                                    title={`${errors.Remitente?.message}`}
                                    getPopupContainer={(node) => node.parentNode as HTMLElement}
                                />
                            )}
                        </div>
                        <div>
                            <TextStyled>Contraseña</TextStyled>
                            <Controller
                                name="Password"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Este campo es obligatorio",
                                    },
                                    maxLength: 255,
                                }}
                                render={({ field }) => (
                                    <InputPasswordStyled
                                        placeholder="*****************"
                                        {...field}
                                    />
                                )}
                            />
                            {errors.Password && (
                                <Tooltip
                                    open={errors.Password ? true : false}
                                    placement="bottomRight"
                                    title={`${errors.Password?.message}`}
                                    getPopupContainer={(node) => node.parentNode as HTMLElement}
                                />
                            )}
                        </div>
                    </EmailConfigColumn>
                </EmailConfigRow1>
                <EmailConfigRow2>
                    <Button type="default" size="large">
                        Cancelar
                    </Button>
                    <Button type="primary" size="large" htmlType="submit">
                        Guardar
                    </Button>
                </EmailConfigRow2>
            </EmailConfigForm>
        </EmailConfigContainer>
    );
};

export default EmailConfig;
