import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
// import jwtDecode from "jwt-decode";

// export interface IToken {
//     aud: string | number | null;
//     exp: string | number | null;
//     iat: string | number | null;
//     id: string | number | null;
//     iss: string | number | null;
//     nbf: string | number | null;
//     roles: string | number | null;
//     username: string | number | null;
// }

export interface ICorreo {
    Email: string | null;
}

export interface IPassword {
    token: string | null;
    newPassword: string | null;
    confirmNewPassword: string | null;
}

export const recoveryApi = createApi({
    reducerPath: "recoveryApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem("token");
            // Asegúrate de que el token no sea nulo antes de agregarlo a los encabezados
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        Solicitud: builder.mutation({
            query: (data: ICorreo) => {
                // const token: string | null = localStorage.getItem("token");
                // const decode: IToken | null = token ? jwtDecode(token) : null;
                return {
                    url: "RECOVER/TOKEN",
                    method: "POST",
                    body: data,
                };
            },
        }),
        Recovery: builder.mutation({
            query: (data: IPassword) => {
                // const token: string | null = localStorage.getItem("token");
                // const decode: IToken | null = token ? jwtDecode(token) : null;
                return {
                    url: "RECOVER/RECOVER",
                    method: "POST",
                    body: data,
                };
            },
        }),
    }),
});

export const { useSolicitudMutation, useRecoveryMutation } = recoveryApi;
