import { createSlice } from "@reduxjs/toolkit";
interface User {
    user: IUsersState;
}
interface IUsersState {
    user: [];
}

export const perfilSlice = createSlice({
    name: "Perfil",
    initialState: {
        users: [],
    },
    reducers: {
        perfilUser: (state, action) => {
            state.users = action.payload;
        },
    },
});

export const { perfilUser } = perfilSlice.actions;

export const selectPerfil = (state: User) => state.user;

export default perfilSlice.reducer;
