import styled from "styled-components";
import { Card, Button } from "@nubeteck/components";

export const SocialLinks = styled.div`
    a {
        margin-right: 8px;
        color: #1890ff;

        &:last-child {
            margin-right: 0;
        }
    }
`;

export const CardWrapper = styled(Card)`
    width: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

export const RoundButton = styled(Button)`
    position: absolute;
    bottom: 5px;
    right: 20px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: "15px";
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

export const ImgModalStyled = styled.img`
    width: 170px;
    height: 170px;
    object-fit: cover;
    border-radius: 90px;
`;
