import { setupListeners } from "@reduxjs/toolkit/query/react";
import { configureStore } from "@reduxjs/toolkit";

import { authApi, usersApi, perfilApi, recoveryApi } from "@api";

import authReducer from "./slices/authSlice";
import usersReducer from "./slices/usersSlice";
import perfilReducer from "./slices/perfilSlice";
import { requestApi } from "src/api/requestApi";
import { dashboardApi } from "src/api/dashboardApi";
import { serviciosApi } from "src/api/serviciosApi";

// interface SlicesModules {
//     moduleName: string;
//     slices: {
//         name: string;
//         slice: Reducer<unknown>;
//     }[];
// }

// const slices = objectify(
//     container.getAll<SlicesModules>("Slices").flatMap((value) => value.slices) || [],
//     (f) => f.name,
//     (f) => f.slice,
// );

export const store = configureStore({
    reducer: {
        // ...slices,
        auth: authReducer,
        users: usersReducer,
        perfil: perfilReducer,
        [recoveryApi.reducerPath]: recoveryApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [perfilApi.reducerPath]: perfilApi.reducer,
        [usersApi.reducerPath]: usersApi.reducer,
        [serviciosApi.reducerPath]: serviciosApi.reducer,
        [requestApi.reducerPath]: requestApi.reducer,
        [dashboardApi.reducerPath]: dashboardApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(authApi.middleware)
            .concat(perfilApi.middleware)
            .concat(serviciosApi.middleware)
            .concat(recoveryApi.middleware)
            .concat(dashboardApi.middleware)
            .concat(requestApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
setupListeners(store.dispatch);
