import { createSlice } from "@reduxjs/toolkit";
interface Users {
    users: IUsersState;
}
interface IUsersState {
    users: [];
}

export const usersSlice = createSlice({
    name: "users",
    initialState: {
        users: [],
    },
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload;
        },
    },
});

export const { setUsers } = usersSlice.actions;

export const selectUsers = (state: Users) => state.users.users;

export default usersSlice.reducer;
