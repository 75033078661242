import React, { useState } from "react";
import { Avatar, Modal, Space, Tooltip } from "@nubeteck/components";
import {
    UserOutlined,
    PhoneOutlined,
    MailOutlined,
    CheckOutlined,
    FieldTimeOutlined,
    LineOutlined,
} from "@ant-design/icons";
import { CardWrapper, SocialLinks, RoundButton, ImgModalStyled } from "./card-users.styled";
import {
    useEditStatePartnerMutation,
    //IRequestShowPartner,
    IDetailsState,
} from "@api";
//import { setRequest } from "src/core/store/slices/requestSlice";

interface ISocialLink {
    UserId: number;
    RedSocialId: number;
    url: string;
}
const redesSociales: Record<number, string> = {
    1: "Linkedin",
    2: "Facebook",
    3: "Instagram",
    4: "Twitter",
    5: "Telegram",
};
interface UserCardProps {
    estadoId: number;
    userId: number;
    source: string;
    name: string;
    phone: string;
    email: string;
    age: number;
    socialLinks: ISocialLink[];
}
export default function CardUsers({
    estadoId,
    userId,
    source,
    name,
    phone,
    email,
    age,
    socialLinks,
}: UserCardProps) {
    let buttonColor, buttonIcon, tooltipText;
    switch (estadoId) {
        case 1:
            buttonColor = "#6bb700";
            buttonIcon = <CheckOutlined size={100} style={{ color: "white", fontSize: "20px" }} />;
            tooltipText = "Socio";
            break;
        case 2:
            buttonColor = "#c4314b";
            buttonIcon = <LineOutlined style={{ color: "white", fontSize: "20px" }} />;
            tooltipText = "Usuario";
            break;
        case 3:
            buttonColor = "#ffaa44";
            buttonIcon = (
                <FieldTimeOutlined
                    style={{ color: "white", fontSize: "30px", marginLeft: "2px" }}
                />
            );
            tooltipText = "Pendiente";
            break;
        default:
            buttonColor = "gray";
            buttonIcon = null;
            tooltipText = "Estado desconocido";
    }
    console.log(estadoId, "que me trae?");

    const [editStatePartner] = useEditStatePartnerMutation();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleModalOk = () => {
        setIsModalVisible(true);
        const data: IDetailsState = { UserId: userId, EstadoId: estadoId === 1 ? 2 : 1 };
        const result = editStatePartner(data);
        console.log(result, "resultt");
    };

    return (
        <div style={{ padding: "16px", background: "#f0f0f0" }}>
            <CardWrapper>
                <Space direction="vertical" size={10}>
                    <div style={{ position: "relative" }}>
                        <Avatar size={240} src={source} icon={<UserOutlined />} />
                        <Tooltip title={tooltipText}>
                            <RoundButton
                                style={{ backgroundColor: buttonColor }}
                                onClick={() => setIsModalVisible(true)}
                            >
                                {buttonIcon}
                            </RoundButton>
                        </Tooltip>
                    </div>
                    <h2>{name}</h2>
                    <p>
                        <PhoneOutlined /> {phone}
                    </p>
                    <p>
                        <MailOutlined /> {email}
                    </p>
                    <p>Edad: {age}</p>
                    <SocialLinks>
                        {socialLinks.map((link, index) => {
                            const nombreRedSocial =
                                redesSociales[link.RedSocialId] || "Desconocida";
                            return (
                                <a
                                    key={index}
                                    href={link.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {nombreRedSocial}
                                </a>
                            );
                        })}
                    </SocialLinks>
                </Space>
            </CardWrapper>

            <Modal
                title={`Estado del Usuario: ${estadoId !== 1 ? "Activar" : "Desactivar"}`}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onOk={() => {
                    handleModalOk();
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <ImgModalStyled src={source} alt="Fotografía" />
                    <h1
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        {estadoId !== 1 ? (
                            <h3>¿Seguro que quiere activar este usuario?</h3>
                        ) : (
                            <h3>¿Seguro que quiere desactivar este usuario?</h3>
                        )}
                    </h1>
                </div>
            </Modal>
        </div>
    );
}
