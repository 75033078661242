import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

// export interface IDashboard {
//     ServiciosCantidadDeServicios: {
//         CantidadDeServiciosTotales: number;
//     };
//     UsersCantidadDeClientes: {
//         CantidadDeClientesTotales: number;
//     };
//     UsersCantidadDeSocios: {
//         CantidadDeSociosTotales: number;
//     };
//     ServiciosPorMes: {
//         Anio: number;
//         NombreMes: string;
//         NumeroMes: number;
//         CantidadDeServiciosPorMes: number;
//     }[];
//     UsersClientesPorMes: {
//         CantidadDeClientesPorMes: number;
//         Anio: number;
//         NombreMes: string;
//         NumeroMes: number;
//         CantidadDeServicios: number;
//     }[];
//     SociosPorMes: {
//         Anio: number;
//         NombreMes: string;
//         NumeroMes: number;
//         CantidadDeSociosPorMes: number;
//     }[];
// }

export const dashboardApi = createApi({
    reducerPath: "dashboardApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem("token");
            // Asegúrate de que el token no sea nulo antes de agregarlo a los encabezados
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        showDashboard: builder.mutation({
            query: () => {
                return {
                    url: `DASHBOARDADMIN`,
                    method: "POST",
                };
            },
        }),
    }),
});

export const { useShowDashboardMutation } = dashboardApi;
