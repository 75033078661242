import "reflect-metadata";
import "./I18next.config";

import React from "react";
import { ThemeProvider } from "@nubeteck/components";
import { QueryClientProvider } from "@tanstack/react-query";
import { Provider as ReduxProvider } from "react-redux";
import { Provider as InversifyProvider } from "inversify-react";

import { store } from "./core";
import { themeColors } from "./core";
import { useDarkMode } from "./hooks";

import container from "./inversify.config";
import Application from "./App";
import queryClient from "./query.config";

const InitializeApp = () => {
    const { isDarkMode } = useDarkMode();

    return (
        <InversifyProvider container={() => container}>
            <ThemeProvider
                theme={{
                    mode: isDarkMode ? "dark" : "light",
                    colors: themeColors,
                    fontFamily: "Mulish",
                    config: { themedScrollbar: true },
                    locale: {
                        emptyText: "Hello",
                        errorText: "Error",
                    },
                }}
            >
                <QueryClientProvider client={queryClient}>
                    <ReduxProvider store={store}>
                        <Application />
                    </ReduxProvider>
                </QueryClientProvider>
            </ThemeProvider>
        </InversifyProvider>
    );
};

export default InitializeApp;
