import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Tooltip } from "@nubeteck/components";

import {
    HeadingStyled,
    InputStyled,
    TextStyled,
    PasswordManagmentDivContainer,
    PasswordManagmentFormcontainer,
    PasswordManagmentFormDivRow,
} from "./password-management.styled";

const PasswordManagement = () => {
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
        watch,
    } = useForm();

    const onSubmit = (data: object) => {
        console.log(data);
        reset({
            Password: "",
            NewPassword: "",
            ConfirmPassword: "",
        });
    };

    return (
        <PasswordManagmentDivContainer>
            <PasswordManagmentFormcontainer onSubmit={handleSubmit(onSubmit)}>
                <HeadingStyled level={3}>Gestión de contraseña</HeadingStyled>
                <PasswordManagmentFormDivRow>
                    <TextStyled>Contraseña</TextStyled>
                    <Controller
                        name="Password"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: {
                                value: true,
                                message: "Este campo es obligatorio",
                            },
                            maxLength: 255,
                        }}
                        render={({ field }) => <InputStyled {...field} />}
                    />
                    {errors.Password && (
                        <Tooltip
                            open={errors.Password ? true : false}
                            placement="bottomRight"
                            title={`${errors.Password?.message}`}
                            getPopupContainer={(node) => node.parentNode as HTMLElement}
                        />
                    )}
                </PasswordManagmentFormDivRow>
                <PasswordManagmentFormDivRow>
                    <TextStyled>Contraseña nueva</TextStyled>
                    <Controller
                        name="NewPassword"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: {
                                value: true,
                                message: "Este campo es obligatorio",
                            },
                            maxLength: 255,
                            pattern: {
                                value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                message:
                                    "La contraseña debe incluir un mínimo de 6 caracteres, con al menos un símbolo, una letra mayúscula, una letra minúscula y un número",
                            },
                            validate: (val: string) => {
                                if (watch("Password") == val) {
                                    return "Esta contraseña ya existe";
                                }
                            },
                        }}
                        render={({ field }) => <InputStyled {...field} />}
                    />
                    {errors.NewPassword && (
                        <Tooltip
                            open={errors.NewPassword ? true : false}
                            placement="bottomRight"
                            title={`${errors.NewPassword?.message}`}
                            getPopupContainer={(node) => node.parentNode as HTMLElement}
                        />
                    )}
                </PasswordManagmentFormDivRow>
                <PasswordManagmentFormDivRow>
                    <TextStyled>Confirme la contraseña</TextStyled>
                    <Controller
                        name="ConfirmPassword"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: {
                                value: true,
                                message: "Este campo es obligatorio",
                            },
                            maxLength: 255,
                            validate: (val: string) => {
                                if (watch("NewPassword") != val) {
                                    return "Tus contraseñas no coinciden";
                                }
                            },
                        }}
                        render={({ field }) => <InputStyled {...field} />}
                    />
                    {errors.ConfirmPassword && (
                        <Tooltip
                            open={errors.ConfirmPassword ? true : false}
                            placement="bottomRight"
                            title={`${errors.ConfirmPassword?.message}`}
                            getPopupContainer={(node) => node.parentNode as HTMLElement}
                        />
                    )}
                </PasswordManagmentFormDivRow>
                <Button type="primary" size="large" htmlType="submit">
                    Guardar cambios
                </Button>
            </PasswordManagmentFormcontainer>
        </PasswordManagmentDivContainer>
    );
};

export default PasswordManagement;
