import styled from "styled-components";
import { Heading, InputPassword, Text } from "@nubeteck/components";

import { devices } from "../../constants";

export const HeadingStyled = styled(Heading)`
    font-weight: 700 !important;
    line-height: 30px !important;
    color: ${({ theme }) => theme.colors.primary900} !important;
    margin-bottom: 20px !important;
`;

export const TextStyled = styled(Text)`
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.primary900};
`;

export const InputStyled = styled(InputPassword)`
    margin-top: 8px;
    border: 1px solid ${({ theme }) => theme.colors.inputBorder};
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
`;

export const PasswordManagmentDivContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    @media ${devices.lg} {
        flex-direction: row;
        justify-content: flex-start;
    }
`;

export const PasswordManagmentFormcontainer = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
    margin-bottom: 64px;

    @media ${devices.lg} {
        margin-bottom: 0;
    }
`;

export const PasswordManagmentFormDivRow = styled.div`
    width: 100%;
`;
