const breakpoints = {
    xs: "320px",
    sm: "480px",
    md: "600px",
    lg: "801px",
    xl: "1025px",
    "2xl": "1281px",
};

export const devices = {
    xs: `(min-width: ${breakpoints.xs})`,
    sm: `(min-width: ${breakpoints.sm})`,
    md: `(min-width: ${breakpoints.md})`,
    lg: `(min-width: ${breakpoints.lg})`,
    xl: `(min-width: ${breakpoints.xl})`,
    "2xl": `(min-width: ${breakpoints["2xl"]})`,
};
