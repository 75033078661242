import styled from "styled-components";
import { Heading, InputPassword, Text, Flex, Input } from "@nubeteck/components";

import { devices } from "../../constants";

export const HeadingStyled = styled(Heading)`
    color: ${({ theme }) => theme.colors.loginHeading} !important;
    margin-bottom: 20px !important;
    font-size: 24px !important;
    text-align: center;
    @media ${devices.lg} {
        margin-bottom: 40px !important;
    }
`;

export const TextStyled = styled(Text)`
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.primary900};
`;

export const InputStyled = styled(Input)`
    margin-top: 8px;
    border: 1px solid ${({ theme }) => theme.colors.inputBorder};
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
    min-width: 130px;
`;

export const InputPasswordStyled = styled(InputPassword)`
    margin-top: 8px;
    border: 1px solid ${({ theme }) => theme.colors.inputBorder};
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
`;

export const EmailConfigContainer = styled(Flex)`
    flex-direction: column;
    align-items: center;
    margin: 32px 0;

    @media ${devices.lg} {
        align-items: flex-start;
    }
`;
export const EmailConfigForm = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 32px;
`;
export const EmailConfigRow1 = styled(Flex)`
    flex-direction: column;

    @media ${devices.lg} {
        flex-direction: row;
        column-gap: 64px;
    }
`;
export const EmailConfigColumn = styled(Flex)`
    flex-direction: column;
    row-gap: 16px;
    margin-top: 16px;
`;
export const EmailConfigRow2 = styled(Flex)`
    justify-content: center;
    column-gap: 24px;

    @media ${devices.lg} {
        justify-content: flex-end;
    }
`;
