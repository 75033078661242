import styled from "styled-components";
import { Flex, Text } from "@nubeteck/components";

export const LoadingBase = styled(Flex)`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    gap: 5px;
`;

export const LoadingText = styled(Text)`
    font-size: 16px;
`;
