export const themeColors = {
    // @nubeteck/components colors
    primary100: "#FDEDCD",
    primary200: "#FCD69B",
    primary300: "#F6B869",
    primary400: "#EE9943",
    primary500: "#E46C0A",
    primary600: "#C45107",
    primary700: "#A43B05",
    primary800: "#842803",
    primary900: "#6D1A01",
    colorBgContainer: "#3B3B3B",
    colorTextHeading: "#ffffff",
    colorTextDescription: "#d1cece",
    colorText: "#ffffff",
    colorBgBase: "#181818",
    colorMenuIcons: "#a7a7a7",

    // Custom colors
    loginHeading: "#808080",
    inputBorder: "#e3e3e3",
    usersCardDesc: "#26395880",
    notificationsHeading: "#263959",
    notificationsText: "#525252",
    integrationsHeading: "#5f5f5f",
    integrationsSubHeading: "#7a7a7a",
};

export type ThemeColors = keyof typeof themeColors;
