import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Icon, ProfileAvatar, Text, Tooltip } from "@nubeteck/components";

import { profilepic1 } from "@assets";
import {
    HeadingStyled,
    InputStyled,
    TextStyled,
    ProfileConfigContainer,
    ProfileCardDivRoles,
    ProfileFormForm,
    ProfileFormDivRow1,
    ProfileFormDivRow5,
    ProfileBaseCard,
} from "./profile-config.styled";

const ProfileConfig = () => {
    return (
        <ProfileConfigContainer>
            <ProfileCard />
            <ProfileForm />
        </ProfileConfigContainer>
    );
};

const ProfileCard = () => {
    return (
        <ProfileBaseCard size="default">
            <ProfileAvatar image={profilepic1} name="Foto de perfil" email={""} alt="Profile pic" />
            <ProfileCardDivRoles>
                <Text>Roles de usuario:</Text>
                <Text>Admin</Text>
            </ProfileCardDivRoles>
        </ProfileBaseCard>
    );
};

const ProfileForm = () => {
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm();
    const onSubmit = (data: object) => {
        console.log(data);
        reset();
    };

    return (
        <div>
            <HeadingStyled level={3}>Configuración del perfil</HeadingStyled>
            <ProfileFormForm onSubmit={handleSubmit(onSubmit)}>
                <ProfileFormDivRow1>
                    <div className="profileForm-div-nombre">
                        <TextStyled>Nombre</TextStyled>
                        <Controller
                            name="Nombre"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: {
                                    value: true,
                                    message: "Este campo es obligatorio",
                                },
                                maxLength: 255,
                            }}
                            render={({ field }) => (
                                <InputStyled
                                    suffix={errors.Nombre ? <Icon name="" size={16} /> : <span />}
                                    {...field}
                                />
                            )}
                        />
                        {errors.Nombre && (
                            <Tooltip
                                open={errors.Nombre ? true : false}
                                placement="topRight"
                                title={`${errors.Nombre?.message}`}
                                getPopupContainer={(node) => node.parentNode as HTMLElement}
                            />
                        )}
                    </div>
                    <div className="profileForm-div-apellido">
                        <TextStyled>Apellido</TextStyled>
                        <Controller
                            name="Apellido"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: {
                                    value: true,
                                    message: "Este campo es obligatorio",
                                },
                                maxLength: 255,
                            }}
                            render={({ field }) => (
                                <InputStyled
                                    suffix={
                                        errors.Apellido ? <Icon name="" size={16} /> : <span />
                                    }
                                    {...field}
                                />
                            )}
                        />
                        {errors.Apellido && (
                            <Tooltip
                                open={errors.Apellido ? true : false}
                                placement="topRight"
                                title={`${errors.Apellido?.message}`}
                                getPopupContainer={(node) => node.parentNode as HTMLElement}
                            />
                        )}
                    </div>
                </ProfileFormDivRow1>
                <div className="profile-form-div-row2">
                    <TextStyled>Correo Electrónico</TextStyled>
                    <Controller
                        name="Email"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: {
                                value: true,
                                message: "Este campo es obligatorio",
                            },
                            maxLength: 255,
                            pattern: {
                                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                message: "Dirección de correo electrónico no válida",
                            },
                        }}
                        render={({ field }) => (
                            <InputStyled
                                suffix={errors.Email ? <Icon name="" size={16} /> : <span />}
                                {...field}
                            />
                        )}
                    />
                    {errors.Email && (
                        <Tooltip
                            open={errors.Email ? true : false}
                            placement="topRight"
                            title={`${errors.Email?.message}`}
                            getPopupContainer={(node) => node.parentNode as HTMLElement}
                        />
                    )}
                </div>
                <div className="profile-form-div-row3">
                    <TextStyled>Número telefónico</TextStyled>
                    <Controller
                        name="Phone"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: {
                                value: true,
                                message: "Este campo es obligatorio",
                            },
                            maxLength: 255,
                            pattern: {
                                value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                                message: "Número de teléfono no válido",
                            },
                        }}
                        render={({ field }) => (
                            <InputStyled
                                suffix={errors.Phone ? <Icon name="" size={16} /> : <span />}
                                {...field}
                            />
                        )}
                    />
                    {errors.Phone && (
                        <Tooltip
                            open={errors.Phone ? true : false}
                            placement="topRight"
                            title={`${errors.Phone?.message}`}
                            getPopupContainer={(node) => node.parentNode as HTMLElement}
                        />
                    )}
                </div>
                <div className="profile-form-div-row4">
                    <TextStyled>Dirección</TextStyled>
                    <Controller
                        name="Address"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: {
                                value: true,
                                message: "Este campo es obligatorio",
                            },
                            maxLength: 255,
                        }}
                        render={({ field }) => (
                            <InputStyled
                                suffix={errors.Address ? <Icon name="" size={16} /> : <span />}
                                {...field}
                            />
                        )}
                    />
                    {errors.Address && (
                        <Tooltip
                            open={errors.Address ? true : false}
                            placement="topRight"
                            title={`${errors.Address?.message}`}
                            getPopupContainer={(node) => node.parentNode as HTMLElement}
                        />
                    )}
                </div>
                <ProfileFormDivRow5>
                    <Button type="default" size="large">
                        Cancelar
                    </Button>
                    <Button type="primary" size="large" htmlType="submit">
                        Guardar
                    </Button>
                </ProfileFormDivRow5>
            </ProfileFormForm>
        </div>
    );
};

export default ProfileConfig;
