import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const usersApi = createApi({
    reducerPath: "usersApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
    }),
    tagTypes: ["Users"],
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: () => "Users",
            providesTags: ["Users"],
        }),
        createUser: builder.mutation({
            query: (newUser) => ({
                url: "/Users",
                method: "POST",
                body: newUser,
            }),
            invalidatesTags: ["Users"],
        }),
        updateUser: builder.mutation({
            query: (updateUser) => ({
                url: "/Users",
                method: "PUT",
                body: updateUser,
            }),
            invalidatesTags: ["Users"],
        }),
        deleteUser: builder.mutation({
            query: (newUser) => ({
                url: "/Users",
                method: "POST",
                body: newUser,
            }),
            invalidatesTags: ["Users"],
        }),
    }),
});

export const {
    useGetUsersQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
} = usersApi;
