import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export interface IUserDetailsRequest {
    UserId: number;
}

export interface IDetailsState {
    UserId: number;
    EstadoId: number;
}

export const detailsApi = createApi({
    reducerPath: "detailsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem("token");
            // Asegúrate de que el token no sea nulo antes de agregarlo a los encabezados
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        showDetails: builder.mutation({
            query: (body: IUserDetailsRequest) => ({
                url: `SOCIODETALLE`,
                method: "POST",
                body,
            }),
        }),
        editStatePartner: builder.mutation({
            query: (body: IDetailsState) => {
                return {
                    url: `Socios/${body.UserId}`,
                    method: "PUT",
                    body,
                };
            },
        }),
    }),
});

export const { useShowDetailsMutation, useEditStatePartnerMutation } = detailsApi;
