import React, { useState } from "react";

import { MainLayout } from "@nubeteck/components";
import taskvoy from "../../assets/taskvoy-logo.png";
import { useNavigate } from "react-router-dom";
import { useUserMutation } from "@api";

interface ICustomMainLayoutProps {
    children: React.ReactNode;
}
// interface IResult {
//     Message: string | null;
//     OperationStatus: string | null;
//     Result: string | Array<IData> | null;
// }

interface Usuario {
    Apellidos: string;
    ArchivoNombre: string;
    Celular: string;
    ContentType: string;
    Direccion: string;
    Dni: string;
    Email: string;
    FotografiaId: number;
    FullName: string;
    IsActive: boolean;
    Nombres: string;
    Password: string;
    Telefono: string;
    UserId: number;
    Username: string;
    data: string;
}

const CustomMainLayout = ({ children }: ICustomMainLayoutProps) => {
    const navigate = useNavigate();
    const [User, { data, isLoading }] = useUserMutation();
    const [perfil, setPerfil] = useState<Usuario[]>();

    React.useEffect(() => {
        User(null);
    }, [User]);

    React.useEffect(() => {
        if (!isLoading && data) {
            setPerfil(data.Result);
        }
    }, [data, isLoading, perfil]);

    return (
        <MainLayout
            logoSrc={taskvoy}
            header={{
                title: "Sistema de gestion de estadisticas",
                onClickAddIcon: () => null,
                onClickHelpIcon: () => null,
                onClickSettingsIcon: () => null,
            }}
            navBarItems={[
                {
                    id: "home",
                    iconName: "home",
                    label: "Inicio",
                    onClick: () => navigate("/home"),
                    children: [],
                },
                {
                    id: "request",
                    iconName: "access_time",
                    label: "Solicitudes",
                    onClick: () => navigate("/request"),
                    children: [],
                },
                {
                    id: "servicos-destacado",
                    iconName: "article",
                    label: "Servicios destacados",
                    onClick: () => navigate("/servicios-destacado"),
                    children: [],
                },
                // {
                //     id: "client",
                //     iconName: "person",
                //     label: "Cliente",
                //     children: [
                //         {
                //             id: "hello12",
                //             iconName: "face",
                //             label: "Hello",
                //             onClick: () => null,
                //         },
                //         {
                //             id: "hello01",
                //             iconName: "more_vert",
                //             label: "Hello",
                //             onClick: () => null,
                //         },
                //     ],
                // },
                // ...menusFormat,
            ]}
            user={{
                avatar:
                    perfil && perfil.length > 0
                        ? `http://taskvoy.nubeteck.com/TaskvoyApi/api/FotoPortada/${perfil[0].FotografiaId}?a=${Math.random}`
                        : "not found",
                name: perfil && perfil.length > 0 ? perfil[0].Username : "Usuario",
            }}
            dropdownItems={[
                { iconName: "face", name: "Face", onClick: () => null },
                { iconName: "settings", name: "Settings", onClick: () => null },
                { iconName: "face", name: "Face2", onClick: () => null },
                { iconName: "face", name: "Face7", onClick: () => null },
            ]}
            breadcrumbItems={[{ title: "Home", href: "./src" }, { title: "Home1" }]}
        >
            {children}
        </MainLayout>
    );
};

export default CustomMainLayout;
