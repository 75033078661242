import React from "react";
import { message } from "antd";
import { Tooltip, Button, Upload } from "@nubeteck/components";
import { useForm, Controller, SubmitHandler } from "react-hook-form";

import {
    HeadingStyled,
    InputStyled,
    InputPasswordStyled,
    SelectStyled,
    TextStyled,
    TextDesc,
    CreateUsersContainer,
    BaseCardStyled,
    ProfileAvatarStyled,
    BaseCardDesc,
    CreateUserContainer,
    CreateUserForm,
    CreateUserFormRow1,
    CreateUserFormRow2,
    CreateUserFormRow1Column1,
    CreateUserFormRow1Column2,
    IconStyled,
} from "./create-users.styled";

import { useCreateUserMutation, useGetUsersQuery } from "src/api/usersApi";
import { useAppDispatch } from "@hooks";

import { setUsers } from "src/core/store/slices/usersSlice";

interface IFormUser {
    Nombre: string;
    Apellido: string;
    Email: string;
    Phone: string;
    Roles: string;
    Address: string;
    Password: string;
    ConfirmPassword: string;
    // ....
}

const CreateUsers = () => {
    const dispatch = useAppDispatch();

    const {
        handleSubmit,
        control,
        reset,
        watch,
        formState: { errors },
    } = useForm<IFormUser>();

    const [createUser, { data: userData, isSuccess: isUserSuccess, isLoading }] =
        useCreateUserMutation();

    const {
        data: usersData,
        isSuccess: isUsersSuccess,
        isLoading: isLoadingUsers,
    } = useGetUsersQuery("");

    const onSubmit: SubmitHandler<IFormUser> = React.useCallback(
        async (data: IFormUser) => {
            await createUser({ ...data });
            console.log(data);
        },
        [createUser],
    );

    React.useEffect(() => {
        if (isUserSuccess && isUsersSuccess) {
            message.success({
                content: "Usuario creado correctamente",
                duration: 2,
            });
            dispatch(setUsers(usersData));
            console.log(userData);
            reset({});
        }
    }, [dispatch, isUserSuccess, isUsersSuccess, reset, userData, usersData]);

    return (
        <CreateUsersContainer>
            <BaseCardStyled>
                <ProfileAvatarStyled
                    image={""}
                    name="Jane Cooper"
                    email={"ArlenMc@example.com"}
                    alt="Profile pic"
                />
                <Upload>
                    <Button type="primary" size="large" htmlType="submit">
                        Subir foto de perfil
                    </Button>
                </Upload>
                <BaseCardDesc>
                    <TextDesc>Actualice de manera rápida su foto de perfil</TextDesc>
                </BaseCardDesc>
            </BaseCardStyled>
            <CreateUserContainer>
                <HeadingStyled>Crear usuario</HeadingStyled>
                <CreateUserForm onSubmit={handleSubmit(onSubmit)}>
                    <CreateUserFormRow1>
                        <CreateUserFormRow1Column1>
                            <div>
                                <TextStyled>Nombre</TextStyled>
                                <Controller
                                    name="Nombre"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Este campo es obligatorio",
                                        },
                                        maxLength: 255,
                                    }}
                                    render={({ field }) => (
                                        <InputStyled
                                            suffix={
                                                errors.Nombre ? (
                                                    <IconStyled name="" size={16} />
                                                ) : (
                                                    <span />
                                                )
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.Nombre && (
                                    <Tooltip
                                        open={errors.Nombre ? true : false}
                                        placement="topRight"
                                        title={`${errors.Nombre?.message}`}
                                        getPopupContainer={(node) => node.parentNode as HTMLElement}
                                    />
                                )}
                            </div>
                            <div>
                                <TextStyled>Apellido</TextStyled>
                                <Controller
                                    name="Apellido"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Este campo es obligatorio",
                                        },
                                        maxLength: 255,
                                    }}
                                    render={({ field }) => (
                                        <InputStyled
                                            suffix={
                                                errors.Apellido ? (
                                                    <IconStyled name="" size={16} />
                                                ) : (
                                                    <span />
                                                )
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.Apellido && (
                                    <Tooltip
                                        open={errors.Apellido ? true : false}
                                        placement="topRight"
                                        title={`${errors.Apellido?.message}`}
                                        getPopupContainer={(node) => node.parentNode as HTMLElement}
                                    />
                                )}
                            </div>
                            <div>
                                <TextStyled>Correo Electrónico</TextStyled>
                                <Controller
                                    name="Email"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Este campo es obligatorio",
                                        },
                                        maxLength: 255,
                                        pattern: {
                                            value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                            message: "Dirección de correo electrónico no válida",
                                        },
                                    }}
                                    render={({ field }) => (
                                        <InputStyled
                                            suffix={
                                                errors.Email ? (
                                                    <IconStyled name="" size={16} />
                                                ) : (
                                                    <span />
                                                )
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.Email && (
                                    <Tooltip
                                        open={errors.Email ? true : false}
                                        placement="topRight"
                                        title={`${errors.Email?.message}`}
                                        getPopupContainer={(node) => node.parentNode as HTMLElement}
                                    />
                                )}
                            </div>
                            <div>
                                <TextStyled>Número telefónico</TextStyled>
                                <Controller
                                    name="Phone"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Este campo es obligatorio",
                                        },
                                        maxLength: 255,
                                        pattern: {
                                            value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                                            message: "Número de teléfono no válido",
                                        },
                                    }}
                                    render={({ field }) => (
                                        <InputStyled
                                            suffix={
                                                errors.Phone ? (
                                                    <IconStyled name="" size={16} />
                                                ) : (
                                                    <span />
                                                )
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.Phone && (
                                    <Tooltip
                                        open={errors.Phone ? true : false}
                                        placement="topRight"
                                        title={`${errors.Phone?.message}`}
                                        getPopupContainer={(node) => node.parentNode as HTMLElement}
                                    />
                                )}
                            </div>
                            <div>
                                <TextStyled>Roles de usuario</TextStyled>
                                <Controller
                                    name="Roles"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        validate: () => {
                                            if (watch("Roles") === "") {
                                                return "Este campo es obligatorio";
                                            }
                                        },
                                    }}
                                    render={({ field }) => (
                                        <SelectStyled /* Border radius must be fixed */
                                            size="large"
                                            suffixIcon={
                                                errors.Roles && <IconStyled name="" size={16} />
                                            }
                                            options={[
                                                {
                                                    value: "",
                                                    label: "Select role",
                                                },
                                                { value: 1, label: "Admin" },
                                                { value: 2, label: "Editor" },
                                                { value: 3, label: "Viewer" },
                                            ]}
                                            {...field}
                                        />
                                    )}
                                />
                                {/* {errors.Address && (
                                    <Tooltip
                                        open={errors.Roles ? true : false}
                                        placement="bottomRight"
                                        title={`${errors.Roles?.message}`}
                                        getPopupContainer={(node) => node.parentNode as HTMLElement}
                                    />
                                )} */}
                            </div>
                        </CreateUserFormRow1Column1>
                        <CreateUserFormRow1Column2>
                            <div>
                                <TextStyled>Dirección</TextStyled>
                                <Controller
                                    name="Address"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Este campo es obligatorio",
                                        },
                                        maxLength: 255,
                                    }}
                                    render={({ field }) => (
                                        <InputStyled
                                            suffix={
                                                errors.Address ? (
                                                    <IconStyled name="" size={16} />
                                                ) : (
                                                    <span />
                                                )
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.Address && (
                                    <Tooltip
                                        open={errors.Address ? true : false}
                                        placement="topRight"
                                        title={`${errors.Address?.message}`}
                                        getPopupContainer={(node) => node.parentNode as HTMLElement}
                                    />
                                )}
                            </div>
                            <div>
                                <TextStyled>Contraseña</TextStyled>
                                <Controller
                                    name="Password"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Este campo es obligatorio",
                                        },
                                        pattern: {
                                            value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                            message:
                                                "La contraseña debe incluir un mínimo de 6 caracteres, con al menos un símbolo, una letra mayúscula, una letra minúscula y un número",
                                        },
                                        maxLength: 255,
                                    }}
                                    render={({ field }) => <InputPasswordStyled {...field} />}
                                />
                                {errors.Password && (
                                    <Tooltip
                                        open={errors.Password ? true : false}
                                        placement="bottomRight"
                                        title={`${errors.Password?.message}`}
                                        getPopupContainer={(node) => node.parentNode as HTMLElement}
                                    />
                                )}
                            </div>
                            <div>
                                <TextStyled>Confirmar contraseña</TextStyled>
                                <Controller
                                    name="ConfirmPassword"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Este campo es obligatorio",
                                        },
                                        maxLength: 255,
                                        validate: (val: string) => {
                                            if (watch("Password") != val) {
                                                return "Tus contraseñas no coinciden";
                                            }
                                        },
                                    }}
                                    render={({ field }) => <InputPasswordStyled {...field} />}
                                />
                                {errors.ConfirmPassword && (
                                    <Tooltip
                                        open={errors.ConfirmPassword ? true : false}
                                        placement="bottomRight"
                                        title={`${errors.ConfirmPassword?.message}`}
                                        getPopupContainer={(node) => node.parentNode as HTMLElement}
                                    />
                                )}
                            </div>
                        </CreateUserFormRow1Column2>
                    </CreateUserFormRow1>
                    <CreateUserFormRow2>
                        <Button type="default" size="large">
                            Cancelar
                        </Button>
                        <Button
                            loading={isLoading || isLoadingUsers}
                            type="primary"
                            size="large"
                            htmlType="submit"
                        >
                            Guardar
                        </Button>
                    </CreateUserFormRow2>
                </CreateUserForm>
            </CreateUserContainer>
        </CreateUsersContainer>
    );
};

export default CreateUsers;
