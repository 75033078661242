import React from "react";

import { bell } from "@assets";
import { Switch } from "@nubeteck/components";

import {
    HeadingStyled,
    SubHeadingStyled,
    TextStyled,
    TextDescStyled,
    ImageStyled,
    NotificationsDivContainer,
    NotificationsDivSettings,
    NotificationsDivItems,
    BaseCardStyled,
    NotificationsDivItem,
    NotificationsDivDesc,
    NotificationsDivHeader,
} from "./notifications.styled";

interface ItemProps {
    text: string;
}

const NotificationItem: React.FC<ItemProps> = ({ text }): JSX.Element => (
    <BaseCardStyled size="small">
        <NotificationsDivItem>
            <TextStyled>{text}</TextStyled>
            <Switch />
        </NotificationsDivItem>
    </BaseCardStyled>
);

const Notifications = () => {
    return (
        <NotificationsDivContainer>
            <NotificationsDivHeader>
                <HeadingStyled level={1}>Configuración de notificaciones</HeadingStyled>
                <SubHeadingStyled level={2}>Notificarme cuando</SubHeadingStyled>
            </NotificationsDivHeader>
            <NotificationsDivSettings>
                <NotificationsDivItems>
                    <NotificationItem text="Se presenten errores del sistema" />
                    <NotificationItem text="Existan actualizaciones fallidas" />
                    <NotificationItem text="Error al descargar documentos" />
                    <NotificationItem text="Reciba correo electrónicos" />
                    <NotificationItem text="Alertas del sistema" />
                    <NotificationItem text="Alertas de seguridad" />
                </NotificationsDivItems>
                <NotificationsDivDesc>
                    <ImageStyled width={227} height={140} src={bell} preview={false} />
                    <TextDescStyled>
                        Reciba, gestiones y controle las notificaciones de manera directa y
                        sencilla. Este al tanto sobre errores, alertas y servicios del sistema.
                    </TextDescStyled>
                </NotificationsDivDesc>
            </NotificationsDivSettings>
        </NotificationsDivContainer>
    );
};

export default Notifications;
