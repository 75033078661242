import React, { useState } from "react";
import { Button, Modal } from "@nubeteck/components";
import { Document, Page, pdfjs } from "react-pdf"; // Importa pdfjs
import { FilePdfOutlined, LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { IArchivo, IArchivos } from "src/views/details/details";
import { themeColors } from "@core";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PdfViewProps {
    archivos: IArchivos;
}

const PdfView: React.FC<PdfViewProps> = ({ archivos }) => {
    const [visible, setVisible] = useState(false);
    const [selectedFile, setSelectedFile] = useState<IArchivo | null>(null);
    const [numPages, setNumPages] = useState<number>(0); // Variable para el número de páginas
    const [pageNumber, setPageNumber] = useState<number>(1); // Variable para el número de página actual
    const archivosArray: IArchivo[] = Object.values(archivos);

    const showModal = (file: IArchivo) => {
        setSelectedFile(file);
        setVisible(true);
    };

    const handleCancel = () => {
        setSelectedFile(null);
        setVisible(false);
    };

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    };

    return (
        <div
            style={{
                flexDirection: "column",
                justifyContent: "space-evenly",
                display: "flex",
                maxHeight: "100%",
                overflow: "auto",
            }}
        >
            <h3 style={{ color: themeColors.colorBgContainer }}>Documentos</h3>
            <div style={{ display: "flex", flexDirection: "row" }}>
                {archivosArray.map((archivo) => (
                    <div key={archivo.id} style={{ justifyContent: "center" }}>
                        <Button
                            style={{
                                height: "70%",
                                backgroundColor: "transparent",
                                border: "transparent",
                                fontFamily: "initial",
                                justifyContent: "center",
                                alignContent: "center",
                            }}
                            icon={<FilePdfOutlined style={{ fontSize: 80, color: "#6E625E" }} />}
                            onClick={() => showModal(archivo)}
                        ></Button>
                        <h1
                            style={{
                                flex: 1,
                                width: "100px",
                                alignSelf: "center",
                                justifyContent: "center",
                                textAlign: "center",
                                marginTop: "5px",
                                marginLeft: "7px",
                                color: themeColors.colorBgContainer,
                            }}
                        >
                            {archivo.titulo}
                        </h1>
                    </div>
                ))}
            </div>
            <Modal
                title={selectedFile?.titulo}
                visible={visible}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cerrar
                    </Button>,
                ]}
            >
                <Document
                    file={
                        "https://pdfseva.com/damian-libro/"
                        // "https://www.planetadelibros.com.mx/libros_contenido_extra/39/38687_1_PrimerCap_Amiga_date_cuenta.pdf"
                    }
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page pageNumber={pageNumber} />
                    <p>
                        Página {pageNumber} de {numPages}
                    </p>
                    <div>
                        <Button
                            onClick={() => setPageNumber((prevPage) => prevPage - 1)}
                            disabled={pageNumber <= 1}
                            icon={<LeftCircleOutlined />}
                        ></Button>
                        <Button
                            onClick={() => setPageNumber((prevPage) => prevPage + 1)}
                            disabled={pageNumber >= numPages}
                            icon={<RightCircleOutlined />}
                        ></Button>
                    </div>
                </Document>
            </Modal>
        </div>
    );
};

export default PdfView;
