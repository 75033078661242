import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export interface IRequestShowPartner {
    UserId: number;
    FechaNacimiento: string;
    OcupacionId: number;
    ExperienciaId: number;
    Habilidades: string;
    AniosExperiencia: number;
    EstadoId: number;
    CedulaArchivoId: number;
    CertificadoAntecedenteId: number;
    ArchivoIdentidadId: number;
    TituloId: number;
    ContactoEmergenciaNombre: string;
    ContactoEmergenciaCedula: string;
    ContactoEmergenciaDireccion: string;
    ContactoEmergenciaTelefono: string;
}

export const requestApi = createApi({
    reducerPath: "requestApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem("token");
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        showPartner: builder.mutation({
            query: () => {
                return {
                    url: `SOCIOLISTADETALLE`,
                    method: "POST",
                };
            },
        }),
    }),
});

export const { useShowPartnerMutation } = requestApi;
