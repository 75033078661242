import React, { useState } from "react";
import { HoverableContainer, TextContainer, Title, SubTitle, Number } from "./resume-card.styled";

interface ResumeCardProps {
    title: string;
    description: string;
    amount: number;
}

const ResumeCard: React.FC<ResumeCardProps> = ({ title, description, amount }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <HoverableContainer
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            isHovered={isHovered}
        >
            <TextContainer>
                <Title isHovered={isHovered}>{title}</Title>
                <SubTitle isHovered={isHovered}>{description}</SubTitle>
            </TextContainer>
            <Number isHovered={isHovered}>{amount}</Number>
        </HoverableContainer>
    );
};

export default ResumeCard;
