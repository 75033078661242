import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { EstruturaEnviarServicio } from "src/views/servicios-descatado/servicio";

export interface Ipostenviar {
    DestacadosLista: EstruturaEnviarServicio[];
}

export interface IDesactivarServicio {
    ServicioDestacadoId: number;
}

export interface IEditServicio {
    ServicioDestacadoId: number;
    ServicioId: number | string;
    FechaInicio: string;
    FechaFin: string;
}

export const serviciosApi = createApi({
    reducerPath: "serviciosApi",

    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASEURL,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem("token");
            // Asegúrate de que el token no sea nulo antes de agregarlo a los encabezados
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getServiciosDestacado: builder.mutation({
            query: () => ({
                url: "SERVICIOSDESTACADOS/INDEX",
                method: "POST",
            }),
        }),
        getServicios: builder.query({
            query: () => {
                return {
                    url: "Servicios",
                    method: "GET",
                };
            },
        }),
        postServicioEnviar: builder.mutation({
            query: (DestacadosLista: Ipostenviar) => {
                return {
                    url: "SERVICIOSDESTACADOS/CREATE",
                    method: "POST",
                    body: DestacadosLista,
                };
            },
        }),
        desactivarServicio: builder.mutation({
            query: (data: IDesactivarServicio) => {
                return {
                    url: "SERVICIOSDESTACADOS/DELETE",
                    method: "POST",
                    body: data,
                };
            },
        }),
        editServicio: builder.mutation({
            query: (data: IEditServicio) => {
                return {
                    url: "SERVICIOSDESTACADOS/EDIT",
                    method: "POST",
                    body: data,
                };
            },
        }),
    }),
});

export const {
    useGetServiciosDestacadoMutation,
    useGetServiciosQuery,
    usePostServicioEnviarMutation,
    useDesactivarServicioMutation,
    useEditServicioMutation,
} = serviciosApi;
