import React from "react";
import { Spin, SpinProps } from "antd";

import { LoadingBase, LoadingText } from "./loading.styled";

type ILoadingProps = SpinProps;

const Loading = (props: ILoadingProps) => {
    return (
        <LoadingBase {...props}>
            <Spin {...props} size="large" />
            <LoadingText>Loading</LoadingText>
        </LoadingBase>
    );
};

export default Loading;
