import React, { useState } from "react";
import { Descriptions, Button } from "antd";
//import { themeColors } from "@core";

export interface IReference {
    Nombre: string;
    Telefono: string;
}

interface CarouselDescriptionsProps {
    title?: string;
    references: IReference[];
}

const CarouselDescriptions: React.FC<CarouselDescriptionsProps> = ({ references }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        if (currentIndex < references.length - 3) {
            setCurrentIndex(currentIndex + 3);
        }
    };

    const prevSlide = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 3);
        }
    };
    return (
        <div>
            {/* <h3 style={{ color: themeColors.colorBgContainer }}>{title}</h3> */}
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {references.slice(currentIndex, currentIndex + 3).map((reference, index) => (
                    <div key={index} style={{ flexBasis: "33.33%", padding: "8px" }}>
                        <Descriptions
                            title={`Referencia #${currentIndex + index + 1}`}
                            bordered
                            column={1}
                            style={{
                                background: "white",
                                borderRadius: "5px",
                            }}
                        >
                            <Descriptions.Item label="Nombre">{reference.Nombre}</Descriptions.Item>
                            <Descriptions.Item label="Teléfono">
                                {reference.Telefono}
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                ))}
            </div>

            <div style={{ textAlign: "center", marginTop: "0px" }}>
                <Button type="primary" onClick={prevSlide} disabled={currentIndex === 0}>
                    Anterior
                </Button>
                <Button
                    type="primary"
                    onClick={nextSlide}
                    disabled={currentIndex >= references.length - 3}
                    style={{ marginLeft: "8px" }}
                >
                    Siguiente
                </Button>
            </div>
        </div>
    );
};

export default CarouselDescriptions;
