import React from "react";
import { LinkedAppCard } from "@nubeteck/components";

import { figma, adobe, github, linkedin } from "@assets";

import {
    HeadingStyled,
    SubHeadingStyled,
    AppsDivContainer,
    IntegrationsDivContainer,
} from "./integrations.styled";

const Integrations = () => {
    return (
        <IntegrationsDivContainer>
            <HeadingStyled>Aplicaciones enlazadas</HeadingStyled>
            <SubHeadingStyled>
                Trabaje de manera fluida junto a sus herramientas favoritas
            </SubHeadingStyled>
            <AppsDivContainer>
                <LinkedAppCard image={figma} name="Figma" onChange={() => null} />
                <LinkedAppCard image={adobe} name="Adobe Xd" onChange={() => null} />
                <LinkedAppCard image={github} name="GitHub" onChange={() => null} />
                <LinkedAppCard image={linkedin} name="Linkedin" onChange={() => null} />
            </AppsDivContainer>
        </IntegrationsDivContainer>
    );
};

export default Integrations;
